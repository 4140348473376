import { applyDiscount } from '@/lib/helpers/prices'
import { usdFormatter } from './pdp'

export const getGroupDiscountRule = (customerGroup, type) => {
  if (customerGroup && customerGroup?.discount_rules && customerGroup?.discount_rules.length > 0) {
    return customerGroup?.discount_rules?.find((rule) => rule?.type === type) || null
  }

  return null
}

export const getGroupDiscountedPrice = (customerGroup, price) => {
  const discount = customerGroup?.discount ? customerGroup?.discount?.amount : null

  return applyDiscount(price, discount)
}

export const proUSerDiscountPricePLP = (regularPrice, salePrice, discount) => {
  let discountPrice = getGroupDiscountedPrice(discount, regularPrice)
  if (+salePrice !== 0 && salePrice !== null) {
    if (+discountPrice > +salePrice) {
      return `${salePrice ? usdFormatter.format(salePrice) : '$00.00'}`
    } else {
      return `${discountPrice ? usdFormatter.format(discountPrice) : '$00.00'}`
    }
  } else {
    return `${discountPrice ? usdFormatter.format(discountPrice) : '$00.00'}`
  }
}
