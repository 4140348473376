import Cookies from 'js-cookie'
import { server } from '@/config/server'

export async function addToCart(lineItems, customerId) {
  if (JSON.stringify(lineItems) === '{}' || lineItems.line_items.length < 1) {
    throw new Error('lineItems is Empty. Please insert lineItems!')
  }
  if (Cookies.get('cart')) {
    const cart = await getCartData(Cookies.get('cart'))
    console.log('~ file: index.js ~ Big commerce cart ~ addToCart ~ cart', cart)
    if (cart && cart?.error?.status == 404) {
      console.log(
        '~ file: index.js ~ Big commerce cart ~ addToCart ~ cart',
        404,
        'Cart is not found'
      )
      const response = await fetch(`${server}/api/bigcommerce/cart-create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customer_id: customerId || null,
          lineItems,
          channel_id: 1,
        }),
      })
      const data = await response.json()

      if (data?.data?.id) {
        Cookies.set('cart', data.data.id, { expires: 30 })
      }
      return data
    } else {
      const response = await fetch(`${server}/api/bigcommerce/add-line-item`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          cartId: Cookies.get('cart'),
          lineItems,
          channel_id: 1,
          customer_id: customerId || null,
        }),
      })

      const data = await response.json()
      return data
    }
  } else {
    const response = await fetch(`${server}/api/bigcommerce/cart-create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        customer_id: customerId || null,
        lineItems,
        channel_id: 1,
      }),
    })
    const data = await response.json()
    if (data?.data?.id) {
      Cookies.set('cart', data.data.id, { expires: 30 })
    }
    return data
  }
}

export async function getCartData(id) {
  if (Cookies.get('cart')) {
    const response = await fetch(`${server}/api/bigcommerce/cart/get`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        cart_id: id ?? Cookies.get('cart'),
        include: 'line_items.physical_items.options',
      }),
    })
    const data = await response.json()
    if (data?.error && +data?.http_code === 404) {
      Cookies.remove('cart')
    }

    return data
  } else return { data: null, message: 'Cart not created' }
}

export async function removeCartItem(itemId) {
  if (Cookies.get('cart')) {
    const response = await fetch(`${server}/api/bigcommerce/cart/delete-item`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        cart_id: Cookies.get('cart'),
        item_id: itemId,
        include: 'line_items.physical_items.options',
      }),
    })
    const data = await response.json()

    if (!data.data && data.status === 'success') {
      //cart deleted
      // Cookies.remove("cart", { path: '' })
      Cookies.remove('cart')
    }
    return data
  } else {
    return {
      data: null,
      message: 'Cart not created',
    }
  }
}

export async function updateCart(product, quantity, customerId, signal) {
  if (Cookies.get('cart')) {
    const response = await fetch(`${server}/api/bigcommerce/cart/update-item`, {
      signal: signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        cart_id: Cookies.get('cart'),
        item_id: product.id,
        customer_id: customerId || null,
        include: 'line_items.physical_items.options',
        line_item: {
          product_id: product.product_id,
          quantity: quantity,
        },
      }),
    })
    const data = await response.json()
    // if(data.data === null && data.status === 'success'){
    //   Cookies.remove("cart", { path: '' })
    // }
    return data
  } else return { data: null, message: 'Cart not created' }
}

export async function getCartMetafields(signal) {
  if (Cookies.get('cart')) {
    const response = await fetch(`${server}/api/bigcommerce/get-cart-metafields`, {
      signal: signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        cartId: Cookies.get('cart'),
      }),
    })
    const data = await response.json()
    return data
  } else return { data: null, message: 'Cart Metafields' }
}

export async function createCartMetafield(payload, signal) {
  if (Cookies.get('cart')) {
    const response = await fetch(`${server}/api/bigcommerce/create-cart-metafields`, {
      signal: signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        cartId: Cookies.get('cart'),
        payload: payload
      }),
    })
    const data = await response.json()
    return data
  } else return { data: null, message: 'Cart Metafields' }
}

export async function updateCartMetafield(payload, metafieldId, signal) {
  if (Cookies.get('cart')) {
    const response = await fetch(`${server}/api/bigcommerce/update-cart-metafields`, {
      signal: signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        cartId: Cookies.get('cart'),
        metafieldId: metafieldId,
        payload: payload
      }),
    })
    const data = await response.json()
    return data
  } else return { data: null, message: 'Cart Metafields' }
}