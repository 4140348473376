import Cookies from 'js-cookie'
import * as types from '@/redux/actionTypes'

import { CartClient } from '@/lib/cart-client'
import { updateCartModal } from './uiAction'
import { enableBodyScroll, disableBodyScroll } from '@/lib/helper'
import { addToCart, removeCartItem, getCartData, updateCart } from '@/lib/api/cart'
import { getQuantityOfCartProduct, getProductByIdForCustomField } from '@/lib/api/products'
import { addToCartEvent } from '@/lib/ga-events'

// Tracking
import { trackEvent } from '@/lib/tracking'

export const addToCartItem = async (lineItem, dispatch, customerId) => {
  disableBodyScroll()
  dispatch({ type: types.ADD_CART_REQUEST })
  console.log('%c lineItem', 'background: #222; color: #bada55')
  console.log('lineItem', lineItem)
  try {
    // for multiple product selection in system builder  i need to pass lineItem as array format so added below params conditionally
    // but it won't break your old add to cart functionality for single product. so please keep this .
    const { data } = await addToCart(
      { line_items: Array.isArray(lineItem) ? lineItem : [lineItem] },
      customerId
    )
    console.log('add to cart data', data)
    data &&
      dispatch({
        type: types.ADD_CART_SUCCESS,
        payload: {
          ...data,
          all_cart_items: data.line_items.physical_items.map((item) => item) || [], //({ ...item, added_to_wishlist: false}))
        },
      })
    data & addToCartEvent(lineItem, data)
    await dispatch(updateCartModal(true))
    await getCart(dispatch)
    // enableBodyScroll()
    // console.log('data', data)
    await trackEvent({
      event: 'Added to Cart',
      lineItem,
      customerId: customerId,
    })
    return data
  } catch (err) {
    // Handle Error Here
    dispatch({ type: types.ADD_CART_FAILURE })
    Cookies.remove('cart')
    addToCartItem(lineItem, dispatch, customerId)
    console.error('addToCart err', err)
    throw err
  }
}

export const addToCartItems = async (lineItems, dispatch, customerId) => {
  disableBodyScroll()
  dispatch({ type: types.ADD_CART_REQUEST })

  try {
    const { data } = await addToCart({ line_items: lineItems }, customerId)

    // console.log("data", data);
    data &&
      dispatch({
        type: types.ADD_CART_SUCCESS,
        payload: {
          ...data,
          all_cart_items: data.line_items.physical_items.map((item) => item) || [],
        },
      })

    data & addToCartEvent(lineItems, data)

    await dispatch(updateCartModal(true))
    await getCart(dispatch)
    // enableBodyScroll()
    // console.log('data', data)
    return data
  } catch (err) {
    // Handle Error Here
    dispatch({ type: types.ADD_CART_FAILURE })
    console.error('addToCart err', err)
    throw err
  }
}

export const addStdProductToCart = async (
  customer,
  modifiers,
  product_id,
  quantity,
  variant_id,
  originalPrice,
  salePrice,
  dispatch
) => {
  const addModifier = await addModifiers(
    product_id,
    variant_id,
    quantity,
    originalPrice,
    salePrice,
    modifiers,
    false
  )
  const data = await addToCartItem(
    addModifier,
    dispatch,
    (customer && customer?.profile && customer?.profile?.id) || null
  )
  return data
}

export const addProProductToCart = async (
  customer,
  modifiers,
  product_id,
  quantity,
  variant_id,
  originalPrice,
  salePRice,
  dispatch,
  variant
) => {
  const addModifier = await addModifiers(
    product_id,
    variant_id,
    quantity,
    originalPrice,
    salePRice,
    modifiers,
    true,
    variant
  )
  addToCartItem(
    addModifier,
    dispatch,
    (customer && customer?.profile && customer?.profile?.id) || null
  )
}

export const checkProInventoryOfProduct = async (
  variant,
  all_cart_items,
  quantity,
  customer,
  modifiers,
  originalPrice,
  salePrice,
  dispatch
) => {
  const productInventory = await getQuantityOfCartProduct(variant?.sku)

  const cartProductQuantity = all_cart_items.find(
    (item) => item?.sku === variant?.sku && item?.options?.find((el) => el.value === '03')
  )?.quantity
  if (productInventory <= cartProductQuantity || productInventory <= 0) {
    if (variant) {
      const modifierAdd = await addModifiers(
        variant?.product_id,
        variant?.id,
        quantity,
        variant?.price || variant?.calculated_price,
        variant?.sale_price,
        modifiers,
        false
      )
      addToCartItem(
        modifierAdd,
        dispatch,
        (customer && customer?.profile && customer?.profile?.id) || null
      )
    }
  } else {
    await addProProductToCart(
      customer,
      modifiers,
      variant?.product_id,
      quantity,
      variant?.id,
      originalPrice,
      variant?.sale_price,
      dispatch,
      variant
    )
  }
}

export const addModifiers = async (
  productId,
  variantId,
  quantity,
  originalPrice,
  salePrice,
  modifiers,
  isProUser,
  variant
) => {
  //fetch product custom option for product_type
  const custom_opt = await getProductByIdForCustomField(productId)
  const productType =
    custom_opt && custom_opt?.custom_fields?.find((item) => item.name == 'product_type')
  let isProductWader = false
  if (productType) {
    if (productType.value.toLowerCase() == 'wader') isProductWader = true
  }

  //check if the product have sale price or not
  let checkIsSale
  let listPrice
  if (isProUser) {
    checkIsSale =
      variant?.sale_price && variant?.sale_price !== 0
        ? +variant?.sale_price < +variant?.price
        : +variant?.price > +originalPrice
    listPrice =
      +variant?.calculated_price > +originalPrice ? originalPrice : variant?.calculated_price
  } else {
    checkIsSale = salePrice && +salePrice !== 0 && +salePrice < +originalPrice
    listPrice = checkIsSale ? +salePrice : +originalPrice
  }

  // if product type is Wader, then set sale modifier
  if (isProductWader) checkIsSale = true

  // for sale modifiers
  let option_value_sale = null
  let option_id_sale = null

  // modifiers for Sale
  modifiers?.find((obj) => {
    if (obj.display_name?.toLowerCase() === 'sale') {
      if (checkIsSale) {
        obj?.option_values?.find((opt) => {
          if (opt?.label === 'Yes') {
            option_id_sale = opt?.option_id
            option_value_sale = opt?.id
          }
        })
      } else {
        obj?.option_values?.find((opt) => {
          if (opt?.label === 'No') {
            option_id_sale = opt?.option_id
            option_value_sale = opt?.id
          }
        })
      }
    }
  })

  // for warehouse modifiers
  let option_value = null
  let option_id = null

  // modifiers for Warehouse
  modifiers?.find((obj) => {
    if (obj.display_name?.toLowerCase() === 'warehouse') {
      obj?.option_values?.map((opt) => {
        if (isProUser && opt?.label === '03') {
          option_id = opt?.option_id
          option_value = opt?.id
          return
        } else if (opt?.label === '02') {
          option_id = opt?.option_id
          option_value = opt?.id
          return
        }
      })
    }
  })

  let optionSection = []

  if (option_id) {
    optionSection = [
      ...optionSection,
      {
        option_id: option_id,
        option_value: option_value,
      },
    ]
  }

  if (option_id_sale) {
    optionSection = [
      ...optionSection,
      {
        option_id: option_id_sale,
        option_value: option_value_sale,
      },
    ]
  }
  let payload = {
    product_id: productId,
    quantity: quantity,
    variant_id: variantId,
    list_price: +listPrice,
    option_selections: optionSection,
  }
  return payload
}

export const getCart = async (dispatch) => {
  dispatch({ type: types.LOAD_CART_REQUEST })

  try {
    const { data } = await getCartData()
    // console.log("getCart", data);

    if (data && data.line_items) {
      dispatch({
        type: types.ADD_CART_SUCCESS,
        payload: {
          ...data,
          all_cart_items: data.line_items.physical_items.map((item) => item), //({ ...item, added_to_wishlist: false}))
        },
      })
    }

    // await dispatch(updateCartModal(true));
    // enableBodyScroll();

    // console.log('data', data)
    return data
  } catch (error) {
    dispatch({ type: types.ADD_CART_FAILURE })
    console.error('get cart error', error)
    throw error
  }
}

export const deleteCartItem = async (itemId, dispatch) => {
  dispatch({ type: types.DELETE_CART_REQUEST })

  try {
    const { data } = await removeCartItem(String(itemId))
    console.log(data)

    if (data && data.id) {
      dispatch({
        type: types.DELETE_CART_SUCCESS,
        payload: {
          ...data,
          all_cart_items: data.line_items.physical_items.map((item) => item),
        },
      })
    } else {
      dispatch({
        type: types.DELETE_CART_SUCCESS,
        payload: {
          base_amount: 0,
          cart_amount: 0,
          channel_id: 0,
          total_item: 0,
          coupons: [],
          created_time: '',
          currency: {},
          customer_id: 0,
          discount_amount: 0,
          email: '',
          id: '',
          line_items: {
            custom_items: [],
            digital_items: [],
            gift_certificates: [],
            physical_items: [],
          },
          locale: 'en',
          tax_included: false,
          updated_time: '',
          all_cart_items: [],
        },
      })
    }

    return data
  } catch (error) {
    dispatch({ type: types.DELETE_CART_FAILURE })
    console.error('deleteCartItem error', error)
    throw error
  }
}

export const updateCartItem = async (product, quantity, dispatch, cart, customerId, signal) => {
  dispatch({ type: types.UPDATE_CART_REQUEST })
  // console.log('caaaaart', cart)
  try {
    const response = await updateCart(product, quantity, customerId, signal)
    // console.log('update data', response)
    if (response.data && response.data.id) {
      // console.log('updateCartItem', response)
      dispatch({
        type: types.UPDATE_CART_SUCCESS,
        payload: {
          ...response.data,
          all_cart_items: response.data.line_items.physical_items.map((item) => item),
        },
      })
    } else {
      console.log('ELSEELSE')
      cart &&
        dispatch({
          type: types.UPDATE_CART_SUCCESS,
          payload: {
            ...cart,
            // updated: !cart.updated
          },
        })
    }

    return response
  } catch (error) {
    dispatch({ type: types.UPDATE_CART_FAILURE })
    // console.error('updateCartItem error', error)
    throw error
  }
}

export const updateCartItemOptions = async (cart, variantId, options, dispatch) => {
  getCart(dispatch)
}

export const clearCartItem = () => async (dispatch, getState) => {
  dispatch({ type: types.CLEAR_CART_REQUEST })

  try {
    return await dispatch({ type: types.CLEAR_CART_SUCCESS })
  } catch (err) {
    return dispatch({ type: types.CLEAR_CART_FAILURE })
  }
}

// export const getAbandonedCart = (t) => async (dispatch) => {
//   dispatch({ type: types.LOAD_CART_REQUEST })
//   try {
//     const { data } = await CartClient.post(`/abandoned/cart`, {
//       data: { token: t },
//     })
//     dispatch({
//       type: types.LOAD_CART_SUCCESS,
//       payload: data,
//     })
//   } catch (err) {
//     // Handle Error Here
//     dispatch({ type: types.LOAD_CART_FAILURE })
//     console.log('getCart err', err)
//     throw err
//   }
// }

// export const updateItemQty = (itemId, quantity) => async (dispatch) => {
//   dispatch({ type: types.UPDATE_ITEM_QTY_REQUEST })
//   try {
//     dispatch({
//       type: types.UPDATE_ITEM_QTY_SUCCESS,
//       payload: { itemId, quantity },
//     })
//   } catch (err) {
//     // Handle Error Here
//     dispatch({ type: types.UPDATE_ITEM_QTY_FAILURE })
//     console.log('updateItemQty err', err)
//   }
// }

// export const updateToCart = (item) => async (dispatch, getState) => {
//   let { cartReducer } = getState()
//   dispatch({ type: types.UPDATE_CART_REQUEST })
//   try {
//     const { data } = await CartClient.put(`/cart`, item)

//     let allCartItems = [...cartReducer.all_cart_items]

//     let updatedAllCartItems = allCartItems.map(cartItem => {

//       let updatedItem = data.line_items.physical_items.find(item => item.id == cartItem.id)

//       if (updatedItem) {
//         return {
//           ...updatedItem,
//           added_to_wishlist: cartItem.added_to_wishlist
//         }
//       }

//       return cartItem
//     })

//     dispatch({
//       type: types.UPDATE_CART_SUCCESS,
//       payload: {
//         ...data,
//         all_cart_items: updatedAllCartItems
//       },
//     })
//   } catch (err) {
//     // Handle Error Here
//     dispatch({ type: types.UPDATE_CART_FAILURE })
//     console.log('updateToCart err', err)
//     throw err
//   }
// }

// export const deleteToCart = (item) => async (dispatch,getState) => {
//   let { cartReducer } = getState()
//   dispatch({ type: types.DELETE_CART_REQUEST })
//   try {
//     const { data } = await CartClient.delete(`/cart`, { data: { itemId: item.id } })

//     const newAllCartItems = cartReducer.all_cart_items.filter(cartItem => {
//       if (cartItem.id != item.id) {
//         return true
//       }

//       if (cartItem.added_to_wishlist == true) {
//         return true
//       }
//     })

//     dispatch({
//       type: types.DELETE_CART_SUCCESS,
//       payload: {
//         data,
//         newAllCartItems
//       },
//     })
//   } catch (err) {
//     // Handle Error Here
//     dispatch({ type: types.DELETE_CART_SUCCESS })
//     console.log('deleteToCart err', err)
//     throw err
//   }
// }

// export const applyProDiscount = () => async (dispatch) => {
//   dispatch({ type: types.APPLY_CART_PRO_REQUEST })
//   try {
//     const { data } = await CartClient.put(`/cart/applyProDiscount`, {})
//     dispatch({
//       type: types.APPLY_CART_PRO_SUCCESS,
//       payload: data,
//     })
//   } catch (err) {
//     // Handle Error Here
//     dispatch({ type: types.APPLY_CART_PRO_SUCCESS })
//     console.log('applyProDiscount err', err)
//   }
// }

// export const removeProDiscount = () => async (dispatch) => {
//   dispatch({ type: types.REMOVE_CART_PRO_REQUEST })
//   try {
//     const { data } = await CartClient.put(`/cart/removeProDiscount`, {})
//     dispatch({
//       type: types.REMOVE_CART_PRO_SUCCESS,
//       payload: data,
//     })
//   } catch (err) {
//     // Handle Error Here
//     dispatch({ type: types.REMOVE_CART_PRO_SUCCESS })
//     console.log('applyProDiscount err', err)
//   }
// }

// export const updateAllCartItems = newAllCartItems => async (dispatch) => {

//   dispatch({type: types.UPDATE_ALL_CART_ITEMS_REQUEST})

//   try {
//     dispatch({
//       type: types.UPDATE_ALL_CART_ITEMS_SUCCESS,
//       payload: newAllCartItems,
//     })
//   } catch (err) {
//     // Handle Error Here
//     dispatch({ type: types.UPDATE_ALL_CART_ITEMS_FAILURE })
//     console.log('updateAllCartItems err', err)
//   }
// }

// export const getCartCheckoutRedirectUrl = async () => {
//   try {
//     const data = await CartClient.get(`/checkout-url`)
//     Cookies.remove('redirect_checkout')
//     return data
//   } catch (err) {
//     // Handle Error Here
//     console.log('getCartCheckoutRedirectUrl err', err)
//     throw err
//   }
// }
