import * as types from '@/redux/actionTypes'
import { CustomerService } from '@/lib/api/customer'
import { RepairService } from '@/lib/repair'
import { getGroupDiscountRule, getGroupDiscountedPrice } from '@/lib/helpers/customer-group'
import { logoutRevokeToken } from '@/lib/ping'
import Cookies from 'js-cookie'
import { getProductsByBigCommerceId, getVariantsWarehouseInventory } from '@/lib/api/products'
import { addToCartItem, clearCartItem } from './cartAction'
import { checkProductWader, getSaleModifies, getWarehouseModifies } from '@/lib/helpers/pdp'

export const onLogin = async ({ email, password }, dispatch) => {
  dispatch({ type: types.AUTH_TOKEN_REQUEST })
  console.log(email, password)
  const login = await CustomerService.login({
    email,
    password,
  })
    .then((res) => {
      if (res && res.success) {
        dispatch({ type: types.AUTH_TOKEN_SUCCESS })
      } else if (res.error == 'Invalid authentication') {
        dispatch({ type: types.AUTH_TOKEN_FAILURE })
      }
      return res
    })
    .catch((error) => {
      dispatch({ type: types.AUTH_TOKEN_FAILURE })
      console.log('Error: AUTH_TOKEN_REQUEST', error)
      throw error
    })

  if (login && login.success) {
    await getCustomer(dispatch)
  }

  return login
}

export const getCreateAccData = async (creteData, dispatch) => {
  dispatch({ type: types.CREATE_ACC_DATA_REQUEST })
  if (creteData) {
    dispatch({ type: types.CREATE_ACC_DATA_SUCCESS, payload: creteData })
  }
}

export const getCustomer = async (dispatch, isShedUSer) => {
  dispatch({ type: types.LOAD_PROFILE_REQUEST })
  const time = new Date(new Date().getTime() - 100 * 60000)

  return await CustomerService.getCustomerDetails({})
    .then(async (response) => {
      let proData
      let groupData
      let profileId
      if (response && response.id) {
        const additionalCustomerDataPromises = [
          promoOrderUser({ promoUserId: response.id }, dispatch).then((res) => (proData = res)),
          dispatch(getCustomerGroup(response?.customer_group_id)).then((res) => (groupData = res)),
        ]
        profileId = response?.id
        await Promise.all(additionalCustomerDataPromises)
        dispatch({ type: types.AUTH_TOKEN_SUCCESS })
        dispatch({ type: types.LOAD_PROFILE_SUCCESS, payload: response })
        if (isShedUSer) {
          return { proData, groupData, profileId }
        } else {
          return { response, groupData, profileId }
        }
      } else {
        dispatch({ type: types.AUTH_TOKEN_FAILURE })
      }
      if (isShedUSer) {
        return { proData, groupData, profileId }
      } else {
        return { response, groupData, profileId }
      }
    })
    .catch((error) => {
      dispatch({ type: types.AUTH_TOKEN_FAILURE })
      dispatch({ type: types.LOAD_PROFILE_FAILURE })
      console.log('Error: LOAD_PROFILE_FAILURE', error)
      throw error
    })
}

export const updateCustomer = async (customer, dispatch) => {
  dispatch({ type: types.UPDATE_PROFILE_REQUEST })

  return await CustomerService.updateCustomer(customer)
    .then((res) => {
      if (res.data && res.data[0] && res.data[0].id) {
        dispatch({ type: types.UPDATE_PROFILE_SUCCESS, payload: res.data[0] })
        return res.data[0]
      }
      return res
    })
    .catch((error) => {
      dispatch({ type: types.UPDATE_PROFILE_FAILURE })
      console.log('Error: UPDATE_PROFILE_FAILURE', error)
      throw error
    })
}

export const updateProDiscountProducts = async (cart, payload, groupData, profileId, dispatch) => {
  let isProSheUSer = payload?.isProAppUser || payload?.isShedAppUser
  if (isProSheUSer && cart.all_cart_items?.length) {
    //
    const filteredSkus = cart.all_cart_items.map((item) => item.sku)
    if (filteredSkus?.length) {
      const data = await getVariantsWarehouseInventory(filteredSkus)
      let params = {
        'id:in': cart?.all_cart_items?.map((item) => item.product_id)?.join(','), //"633,635",
        include: 'modifiers,custom_fields',
      }
      const res = await getProductsByBigCommerceId(params)

      let updatedCart = updateCartWithModifiers(cart.all_cart_items, data, res?.data, groupData)
      Cookies.remove('cart')
      await dispatch(clearCartItem()).then(async (res) => {
        const addToCartItems = []

        updatedCart.forEach(async (data) => {
          let sale_modifiers = getSaleModifies(data, null, data?.isProCart)

          let warehouse_modifiers = getWarehouseModifies(data, data?.isProCart)
          {
            addToCartItems.push({
              product_id: data.product_id,
              quantity: data.quantity,
              variant_id: data.variant_id,
              list_price: +data.list_price,
              option_selections: [
                {
                  option_id: warehouse_modifiers?.option_id_warehouse,
                  option_value: warehouse_modifiers?.option_value_warehouse,
                },
                {
                  option_id: sale_modifiers?.option_id_sale,
                  option_value: sale_modifiers?.option_value_sale,
                },
              ],
            })
          }
        })
        addToCartItems.length > 0 &&
          (await addToCartItem(addToCartItems, dispatch, profileId || null).then((res) => {
            return res
          }))
      })
    }
  }
}

function updateCartWithModifiers(cart, inventory, modifier, groupData) {
  let updatedCart = []
  cart.forEach((cartItem) => {
    let matchingInventoryItem = inventory.find((item) => item.variant_sku === cartItem.sku)
    let findMatchProducts = modifier?.find(
      (mod) => mod?.sku === cartItem?.sku?.split('-')?.[0] || mod?.id === cartItem?.product_id
    ) // for dev some product have different sku with the variant products
    let modifiersItem = findMatchProducts && findMatchProducts?.modifiers
    let discountPrice = parseFloat(getGroupDiscountedPrice(groupData, cartItem?.variant?.price))
    const isProductWader = findMatchProducts && checkProductWader(findMatchProducts?.custom_fields)

    if (matchingInventoryItem && !isProductWader) {
      let proQuantity = parseInt(matchingInventoryItem.pro_quantity)
      let cartQuantity = parseInt(cartItem.quantity)
      if (proQuantity >= cartQuantity) {
        updatedCart.push({
          ...cartItem,
          modifiers: modifiersItem,
          list_price:
            cartItem?.variant.sale_price && cartItem?.variant.sale_price !== 0
              ? +cartItem?.variant.sale_price < discountPrice
                ? cartItem?.variant.sale_price
                : discountPrice
              : discountPrice,
          isProCart: true,
        })
      } else {
        let remainingQuantity = cartQuantity - proQuantity
        if (proQuantity !== 0) {
          updatedCart.push({
            ...cartItem,
            quantity: proQuantity,
            modifiers: modifiersItem,
            list_price:
              cartItem?.variant.sale_price && cartItem?.variant.sale_price !== 0
                ? +cartItem?.variant.sale_price < discountPrice
                  ? cartItem?.variant.sale_price
                  : discountPrice
                : discountPrice,
            isProCart: true,
          })
        }

        // Create a separate cart item for the remaining quantity
        updatedCart.push({
          ...cartItem,
          quantity: remainingQuantity,
          modifiers: modifiersItem,
          isProCart: false,
        })
      }
    } else {
      // If no matching inventory item found, keep the cart item unchanged
      updatedCart.push({ ...cartItem, modifiers: modifiersItem, isProCart: false })
    }
  })
  return updatedCart
}

export const updateCustomerAddress = async (address, dispatch) => {
  dispatch({ type: types.UPDATE_PROFILE_REQUEST })

  return await CustomerService.updateCustomerAddress({ data: address })
    .then((res) => {
      // console.log('%c update cus address', 'background: #222; color: #bada55');
      // console.log('update cus address', address);
      // console.log('update cus address response', res);
      if (res.data && res.data.length > 0) {
        dispatch({
          type: types.UPDATE_PROFILE_ADDRESS_SUCCESS,
          payload: res.data,
        })
        return res.data
      }
      return res
    })
    .catch((error) => {
      dispatch({ type: types.UPDATE_PROFILE_FAILURE })
      console.log('Error: UPDATE_PROFILE_FAILURE', error)
      throw error
    })
}

export const addCustomerAddress = async (address, dispatch) => {
  dispatch({ type: types.UPDATE_PROFILE_REQUEST })

  return await CustomerService.addCustomerAddress(address)
    .then((res) => {
      if (res.data && res.data.length > 0) {
        dispatch({
          type: types.ADD_PROFILE_ADDRESS_SUCCESS,
          payload: res.data,
        })
        return res.data
      }
      return res
    })
    .catch((error) => {
      dispatch({ type: types.UPDATE_PROFILE_FAILURE })
      console.log('Error: ADD_PROFILE_FAILURE', error)
      throw error
    })
}

export const getCustomerOrders = async (params, dispatch) => {
  dispatch({ type: types.UPDATE_PROFILE_REQUEST })

  return await CustomerService.getCustomerOrders(params)
    .then((res) => {
      if (res && res.length > 0) {
        dispatch({ type: types.LOAD_PROFILE_ORDERS_SUCCESS, orders: res })
        return res
      }
      return res
    })
    .catch((error) => {
      dispatch({ type: types.UPDATE_PROFILE_FAILURE })
      console.log('Error: ADD_PROFILE_FAILURE', error)
      throw error
    })
}

export const getCustomerAddresses = async (customerId, dispatch) => {
  dispatch({ type: types.LOAD_ADDRESSES_REQUEST })

  return await CustomerService.getCustomerAddresses(customerId)
    .then((res) => {
      // console.log('Get Customer Addresses', res)

      if (res.data && res.data[0] && res.data[0].id) {
        dispatch({ type: types.LOAD_ADDRESSES_SUCCESS, payload: res.data })
        return res
      }
      return res
    })
    .catch((error) => {
      dispatch({ type: types.LOAD_ADDRESSES_FAILURE })
      throw error
    })
}

export const getCustomerWishlists = async (customerId, dispatch) => {
  dispatch({ type: types.LOAD_WISHLIST_REQUEST })

  return await CustomerService.getCustomerAllWishlists(customerId)
    .then((res) => {
      // console.log('get CustomerWishlists', res)

      if (res.data && res.data) {
        dispatch({ type: types.LOAD_WISHLIST_SUCCESS, payload: res.data })
      }
      return res
    })
    .catch((error) => {
      // dispatch({ type: types.LOAD_WISHLIST_FAILURE })
      console.log('Error: LOAD_WISHLIST_FAILURE', error)
      throw error
    })
}

export const deleteCustomerWishlist = async (params, dispatch) => {
  dispatch({ type: types.DELETE_WISHLIST_REQUEST })

  const deleteResponse = await CustomerService.deleteCustomerWishlist(params)
    .then((res) => res)
    .catch((error) => {
      dispatch({ type: types.DELETE_WISHLIST_FAILURE })
      console.log('Error: DELETE_WISHLIST_FAILURE', error)
      throw error
    })

  if (deleteResponse.status == 200) {
    return await CustomerService.getCustomerAllWishlists(params.customerId)
      .then((res) => {
        // console.log('get CustomerWishlists', res)

        if (res.data && res.data) {
          dispatch({ type: types.DELETE_WISHLIST_SUCCESS })
          dispatch({ type: types.LOAD_WISHLIST_SUCCESS, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        // dispatch({ type: types.LOAD_WISHLIST_FAILURE })
        console.log('Error: LOAD_WISHLIST_FAILURE', error)
        throw error
      })
  }
}

export const deleteCustomerWishlistItem = async (params) => {
  const deleteResponse = await CustomerService.deleteCustomerWishlistItem(params)
    .then((res) => res)
    .catch((error) => {
      console.log('Error: DELETE_WISHLIST_FAILURE', error)
      throw error
    })

  return deleteResponse
}

export const createCustomerWishlist = async (params, dispatch) => {
  dispatch({ type: types.ADD_WISHLIST_REQUEST })

  const createResponse = await CustomerService.createCustomerWishlist(params)
    .then((res) => {
      // console.log('createCustomerWishlist', res)
      return res
    })
    .catch((error) => {
      dispatch({ type: types.ADD_WISHLIST_FAILURE })
      console.log('Error: ADD_WISHLIST_FAILURE', error)
      throw error
    })

  if (createResponse.data) {
    return await CustomerService.getCustomerAllWishlists(params.customerId)
      .then((res) => {
        console.log('get CustomerWishlists', res)

        if (res.data && res.data) {
          dispatch({ type: types.ADD_WISHLIST_SUCCESS })
          dispatch({ type: types.LOAD_WISHLIST_SUCCESS, payload: res.data })
        }
        return res
      })
      .catch((error) => {
        dispatch({ type: types.ADD_WISHLIST_FAILURE })
        console.log('Error: ADD_WISHLIST_FAILURE', error)
        throw error
      })
  }
}

export const logoutCustomer = async (dispatch) => {
  await logoutRevokeToken()
    .then((res) => {})
    .catch((error) => {
      console.log('ERROR in logout data ', error, error.response)
    })
  return await CustomerService.logoutCustomer()
    .then((response) => {
      if (response.success) {
        dispatch({ type: types.AUTH_SIGNOUT_REQUEST })
        dispatch({
          type: types.DELETE_CART_SUCCESS,
          payload: {
            base_amount: 0,
            cart_amount: 0,
            channel_id: 0,
            total_item: 0,
            coupons: [],
            created_time: '',
            currency: {},
            customer_id: 0,
            discount_amount: 0,
            email: '',
            id: '',
            line_items: {
              custom_items: [],
              digital_items: [],
              gift_certificates: [],
              physical_items: [],
            },
            locale: 'en',
            tax_included: false,
            updated_time: '',
            all_cart_items: [],
          },
        })
      }
      return response
    })
    .catch((error) => {
      throw error
    })
}

export const loadCustomerRepairs = async (dispatch, params) => {
  dispatch({ type: types.LOAD_REPAIRS_REQUEST })

  return await RepairService.getRepairs(params)
    .then((res) => {
      if (res && res?.data?.length && res?.data.length > 0) {
        // dispatch({ type: types.LOAD_REPAIRS_SUCCESS, payload: res })
        return res
      }
      return []
    })
    .catch((error) => {
      dispatch({ type: types.LOAD_REPAIRS_FAILURE })
      throw error
    })
}

export const promoOrderUser = async (promoUser, dispatch) => {
  dispatch({ type: types.PROMO_ORDER_USER_REQUEST })
  let isPromoUser = false
  let isProAppUser = false
  let isShedAppUser = false
  let is_application_about_to_expire
  let is_shed_application_about_to_expire
  let pro_program_application_status
  let shed_program_application_status
  await CustomerService.getCustomerFlags().then((res) => {
    if (res?.data) {
      isPromoUser = res?.data?.find((promoUser) => promoUser?.promo_order)?.promo_order
      isProAppUser = res?.data[0].pro_user
      isShedAppUser = res?.data[0]?.shed_user
      is_application_about_to_expire = res?.data[0].is_application_about_to_expire
      is_shed_application_about_to_expire = res?.data[0].is_shed_application_about_to_expire
      pro_program_application_status = res?.data[0].pro_program_application_status
      shed_program_application_status = res?.data[0].shed_application_status
      console.log(
        '---',
        isPromoUser,
        isProAppUser,
        is_application_about_to_expire,
        is_shed_application_about_to_expire,
        pro_program_application_status,
        res?.data
      )
    }
    if (res?.errorMessage) {
      return dispatch({ type: types.PROMO_ORDER_USER_FAILURE })
    }
  })
  try {
    return await dispatch({
      type: types.PROMO_ORDER_USER_SUCCESS,
      payload: {
        isPromoUser,
        isProAppUser,
        isShedAppUser,
        is_application_about_to_expire,
        is_shed_application_about_to_expire,
        pro_program_application_status,
        shed_program_application_status,
      },
    })
  } catch (err) {
    return dispatch({ type: types.PROMO_ORDER_USER_FAILURE })
  }
}

export const getCustomerGroup = (groupId) => async (dispatch, getState) => {
  try {
    dispatch({ type: types.LOAD_CUSTOMER_GROUP_REQUEST })

    const {
      userReducer: { customerGroup },
    } = getState()

    // data exist don't fetch
    if (customerGroup) {
      return null
    }

    const group = await CustomerService.getCustomerGroup(groupId)

    // set null if nothing is fetched
    if (!group) {
      return null
    }

    const defaultRule = getGroupDiscountRule(group, 'all')

    const groupData = {
      id: group?.id,
      name: group?.name,
      isGovXUser:
        group?.name?.toLowerCase()?.replaceAll(' ', '') === 'activemilitary' ||
        group?.name?.toLowerCase()?.replaceAll(' ', '') === 'veterans' ||
        group?.name?.toLowerCase()?.replaceAll(' ', '') === 'policefire&ems',
      discount: defaultRule
        ? {
            method: defaultRule?.method,
            amount: Number(defaultRule?.amount),
          }
        : null,
    }

    dispatch({ type: types.LOAD_CUSTOMER_GROUP_SUCCESS, payload: groupData })
    return groupData
  } catch (error) {
    console.error('getCustomerGroup action error:', error)
    dispatch({ type: types.LOAD_CUSTOMER_GROUP_FAILURE })
    throw error
  }
}

export const clearCustomerData = () => async (dispatch) => {
  dispatch({ type: types.CLEAR_CUSTOMER_DATA_REQUEST })
  try {
    return await dispatch({ type: types.CLEAR_CUSTOMER_DATA_SUCCESS })
  } catch (err) {
    return dispatch({ type: types.CLEAR_CUSTOMER_DATA_FAILURE })
  }
}

export const onUnauthorizedToken = () => async (dispatch) => {
  dispatch({ type: types.AUTH_SIGNOUT_REQUEST })
  try {
    await logoutRevokeToken()
      .then((res) => {})
      .catch((error) => {
        console.log('ERROR in logout data ', error, error.response)
      })
    dispatch({ type: types.AUTH_SIGNOUT_SUCCESS, payload: {} })
    Cookies.remove('customer_token')
    Cookies.remove('sitka_customer_token', { domain: process.env.NEXT_PUBLIC_SUB_DOMAIN })
    setTimeout(() => {
      window.location.replace('/?login=true')
    }, 2000)
  } catch (err) {
    dispatch({ type: types.AUTH_SIGNOUT_FAILURE })
    console.log('err', err)
    throw err
    //throw new Error('list not found')
  }
}
